import React, { Fragment } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import NavCards from "../components/NavCards";
import BtnCards from "../components/BtnCards";

const Home = () => (
  <Fragment>
    {/* <Hero /> */}
    {/* <hr /> */}
    {/* <Content /> */}
    <BtnCards></BtnCards>
    <NavCards></NavCards>
  </Fragment>
);

export default Home;
