 import React from "react";


import { Link } from 'react-router-dom';

import {
  
  Button,

} from "reactstrap";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBContainer
} from 'mdb-react-ui-kit';
import { useAuth0 } from "@auth0/auth0-react";

const NavCards = () => {
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();
    // const toggle = () => setIsOpen(!isOpen);
    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });

    return (
        <div className="" style={{ marginBottom: "100px", marginTop:"50px" }}>
             <h2 className="mb-4 text-center">More Branding Examples: Click each button to visit each site.</h2>
        <MDBRow center>
        <MDBCol >
          <MDBCard>
            <MDBCardImage src='https://mfc.fit/vidbg.png' position='top' alt='...' />
            <MDBCardBody>
              <MDBCardTitle>More Branding By Org ID</MDBCardTitle>
              <MDBCardText>
                This site will demonstrate how you can brand by org and have wildly different login experiences. Including a video background with a transparent widget.
              </MDBCardText>
              <MDBBtn href='https://artists-orgs.vercel.app/' target="_blank">Music Artists</MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol><MDBCard>
            <MDBCardImage src='https://mfc.fit/carousel.png' position='top' alt='...' style={{height: '378.27px'}} />
            <MDBCardBody>
              <MDBCardTitle>More Login Experiences and Branding By Org ID</MDBCardTitle>
              <MDBCardText>
                More examples of different login experiences and branding by organizations with a custom sign up. Also, includes a carousel login.
              </MDBCardText>
              <MDBBtn href='https://www.authdemolab.com/' target="_blank">Carousel Login &amp; more</MDBBtn>
            </MDBCardBody>
          </MDBCard></MDBCol>
          </MDBRow>
      </div>
        );
   

  
};



export default NavCards;